<template>
  <div class="form-wrapper">
    <div class="form-inner">
      <div class="order-infomration">
        <el-card class="box-card related-meals-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('Meals') }}</span>
          </div>
          <el-form class="related-meals-form" ref="related-meals-form" :model="form" label-width="150px" label-position="left">
            <div class="row" v-if="form.meals.length !== 0">
              <div class="selected-meals-list">
                <div class="selected-meal" v-for="(meal, mealIndex) in selectedmeals">
                  <div class="meal-info">
                    <div class="meal-image-wrapper">
                      <img class="meal-image img-fluid" v-if="meal.image" :src="meal.image" />
                      <img class="meal-image img-fluid" v-else src="@/assets/no-image.jpg" />
                    </div>
                    <div class="meal-name" v-if="langcode === 'zh'">
                      {{ meal.name_zh }}
                    </div>
                    <div class="meal-name" v-else>
                      {{ meal.name_en }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
        </el-card>

        <div class="order-amount">
          <el-card class="box-card related-order-amount-card">
            <div slot="header" class="clearfix">
              <span>{{ $t('Order Amount') }}</span>
            </div>
            <div slot="header" class="clearfix">
              <span>{{ $t('Order Amount') }}</span>
            </div>
            <div class="amount-content">
              {{ $t('Total Amount')}}: HK$ {{ getTotalAmount() }}
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';

export default {
  name: 'OrderForm',
  data(){
    //Default form value
    let form = {
      memberId: null,
      member: null,
      meals: [],
      deliveryMethod: '',
      recipientName: '',
      recipientPhone: '',
      recipientAddress: '',
      district: '',
      region: '',
      orderStatus: '',
      paymentPhoto: '',
      paymentMethod: '',
      remark: '',
    };

    return {
      form,
      sameWithMember: false,
      selectedmeals: [],
      currentOption: '',
      totalAmount: 0,
      orderLocked: false,
      districts: {
        'Hong Kong Island': [
          this.$t('Central and Western'),
          this.$t('Eastern'),
          this.$t('Southern'),
          this.$t('Wan Chai'),
        ],
        'Kowloon': [
          this.$t('Kowloon City'),
          this.$t('Kwun Tong'),
          this.$t('Sham Shui Po'),
          this.$t('Wong Tai Sin'),
          this.$t('Yau Tsim Mong')
        ],
        'New Territories': [
          this.$t('Islands'),
          this.$t('Kwai Tsing'),
          this.$t('North'),
          this.$t('Sai Kung'),
          this.$t('Sha Tin'),
          this.$t('Tai Po'),
          this.$t('Tsuen Wan'),
          this.$t('Tuen Mun'),
          this.$t('Yuen Long'),
        ],
      },
    };
  },
  methods:{
    onCheckSame(){
      if(this.sameWithMember && this.form.member){
        this.form.recipientName = this.form.member.name;
        this.form.recipientPhone = this.form.member.phone;
      }else{
        this.form.recipientName = '';
        this.form.recipientPhone = '';
      }
    },
    loadVariations(variationString){
      const variations = JSON.parse(variationString);
      return variations;
    },
    updatemeals(meals){
      this.displaymealForm = false;
      this.selectedmeals = meals;

      const mealInfos = [];

      for(let i = 0; i < meals.length; i++){
        const meal = meals[i];
        const variations = JSON.parse(meal.meal_variations);
        const mealInfo = {
          id: meal.id,
          variations: [],
          quantity: null,
        };
        for(let a = 0 ; a < variations.length; a++){
          const variation = variations[a];
          if(variation){
            const variationInfo = {
              'variation_name_en': variation.variation_name_en,
              'variation_name_zh': variation.variation_name_zh,
              'option': '',
            };
            mealInfo.variations.push(variationInfo);
          }
        }
        mealInfos.push(mealInfo);
      }
      const existedmeals = this.form.meals;
      if(existedmeals.length > 0){
        const newmeals = Common.findNonDuplicate(existedmeals, mealInfos);
        this.form.meals = existedmeals.concat(newmeals);
      }else{
        this.form.meals = mealInfos;
      }
    },
    async save(){
      this.$emit('action', this.form);
    },
    getmealImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    getmealPrice(meal, mealQuantity){
      const mealPrice = meal['meal_regular_price'];
      const mealAmount = mealPrice * mealQuantity;
      return mealAmount;
    },
    getOptionInfo(meal, variation, option){
      const fullmealInfos = this.selectedmeals;
      const mealInfo = fullmealInfos.find(function(item){
        return meal.id === item.id;
      });
      const variationInfo = JSON.parse(mealInfo.meal_variations).find(function(item){
        if(variation.variation_name_en === item.variation_name_en || variation.variation_name_zh === item.variation_name_zh){
          return true;
        }
      });
      const optionInfo = variationInfo.options.find(function(item){
        if(option === item.option_name_en || option === item.option_name_zh){
          return true;
        }
      });
      return optionInfo;
    },
    getTotalAmount(){
      let totalAmount = 0;
      const currentmeals = this.form.meals;
      const fullmealInfos = this.selectedmeals;

      for(let i = 0; i < currentmeals.length; i++ ){
        const currentmeal = currentmeals[i];
        const mealInfo = fullmealInfos.find(function(item){
          return currentmeal.id === item.id;
        });
        const mealPrice = mealInfo['meal_regular_price'];
        const mealAmount = mealPrice * currentmeal.quantity;
        totalAmount += mealAmount;
      }

      return totalAmount;
    },
  },
  watch: {
    'currentOrder': function(){
      const member = this.currentOrder.related_member;
      this.form = {
        member: {
          id: member.id,
          name: member.member_name,
          email: member.member_email,
          phone: member.member_phone,
        },
        orderStatus: this.currentOrder.order_status,
        memberId: member.id,
        paymentMethod: this.currentOrder.payment_method,
        remark: this.currentOrder.order_remark,
        deliveryMethod: this.currentOrder.delivery_method,
        recipientName: this.currentOrder.recipient_name,
        recipientPhone: this.currentOrder.recipient_phone_number,
        recipientAddress: this.currentOrder.address,
        district: this.currentOrder.district,
        region: this.currentOrder.region,
        meals: JSON.parse(this.currentOrder.related_meal),
      };
      if(this.currentOrder.order_status === 'Completed' || this.currentOrder.order_status === 'Cancelled'){
        this.orderLocked = true;
      }
      this.selectedmeals = this.currentOrder.meal_infos;
    },
  },
  computed: mapState({
    currentOrder: state => state.order.currentOrder,
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.order{
  .form-inner{
    display: flex;
  }
  .action-button-wrapper{
    text-align: right;
    margin-bottom: 50px;
  }
  .form-wrapper{
    padding-left: 20px;
  }
  .related-member-form, .related-delivery-form, .order-status-form, .payment-form{
    padding-left: 20px;
  }
  .related-member-card{
    margin-top: 50px;
  }
  .create-member-form{
    margin-top: 50px;
    padding-left: 20px;

    .email{
      margin-right: 50px;
    }
  }
  .el-card__header{
    background-color: black;
    color: white;
  }

  .related-meals-card{
    margin-top: 50px;
  }
  .related-meals-form{
    padding-left: 20px;
  }

  .option-number{
    margin-top: 30px;
  }

  .selected-meals-list{
    margin-top: 50px;
    .selected-meal{
      display: flex;
      margin-bottom: 30px;
    }
    .meal-image-wrapper{
      width: 110px;
      height: 110px;
      position: relative;
      overflow: hidden;
      .meal-image{
        overflow: hidden;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .meal-info{
      margin-right: 30px;
    }
  }
  .payment-card{
    margin-top: 50px;
  }
  .delivery-card{
    margin-top: 50px;
    .checkbox{
      position: relative;
      right: 15px;
      bottom: 10px;
    }
  }
  .region-district{
    .el-form-item{
      margin-right: 50px;
      &:last-child{
        margin-right: 0;
      }
    }
  }

  .remark-card{
    margin-top: 50px;
    .el-textarea__inner{
      height: 100px !important;
    }
  }


  .order-infomration{
    margin-right: 50px;
  }
  .order-amount{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
  }
  .meal-amount{
    margin-top: 20px;
  }
}
</style>
